/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Assignment } from '../models';
// @ts-ignore
import type { AssignmentAttempt } from '../models';
// @ts-ignore
import type { AssignmentAttemptReviewStatusEnum } from '../models';
// @ts-ignore
import type { AssignmentReview } from '../models';
// @ts-ignore
import type { AssignmentReviewerRequest } from '../models';
// @ts-ignore
import type { BankAccount } from '../models';
// @ts-ignore
import type { BankAccountNotSetUp } from '../models';
// @ts-ignore
import type { BankAccountNotionEntries } from '../models';
// @ts-ignore
import type { BankAccountPartialContent } from '../models';
// @ts-ignore
import type { BankAccountRateLimit } from '../models';
// @ts-ignore
import type { BankAccountRequest } from '../models';
// @ts-ignore
import type { CustomDocumentRequest } from '../models';
// @ts-ignore
import type { GenerateProblemSourcesRequestRequest } from '../models';
// @ts-ignore
import type { GenerateProblemSourcesResponse } from '../models';
// @ts-ignore
import type { GenericErrorResponse } from '../models';
// @ts-ignore
import type { GocardlessAuthLinkResponse } from '../models';
// @ts-ignore
import type { GocardlessGetAccountsResponse } from '../models';
// @ts-ignore
import type { GocardlessGetInstitutionResponse } from '../models';
// @ts-ignore
import type { GocardlessSetAccountsRequestRequest } from '../models';
// @ts-ignore
import type { GocardlessSetInstitutionRequestRequest } from '../models';
// @ts-ignore
import type { GoogleDriveFoldersResponse } from '../models';
// @ts-ignore
import type { InitiatePdfPrintRequestRequest } from '../models';
// @ts-ignore
import type { InitiatePdfPrintResponse } from '../models';
// @ts-ignore
import type { PaginatedAssignmentAttemptList } from '../models';
// @ts-ignore
import type { PaginatedAssignmentListList } from '../models';
// @ts-ignore
import type { PaginatedAssignmentReviewListList } from '../models';
// @ts-ignore
import type { PaginatedBankAccountList } from '../models';
// @ts-ignore
import type { PaginatedPageCacheKeyBaseList } from '../models';
// @ts-ignore
import type { PaginatedProblemSourceGeneratorListList } from '../models';
// @ts-ignore
import type { PaginatedProblemSourceListingListList } from '../models';
// @ts-ignore
import type { PaginatedProblemSourceSectionListList } from '../models';
// @ts-ignore
import type { PaginatedProblemsImportAttemptList } from '../models';
// @ts-ignore
import type { PaginatedProblemsImportCandidateList } from '../models';
// @ts-ignore
import type { PaginatedProblemsImportImageList } from '../models';
// @ts-ignore
import type { PaginatedSubjectList } from '../models';
// @ts-ignore
import type { PaginatedVideoList } from '../models';
// @ts-ignore
import type { PatchedBankAccountRequest } from '../models';
// @ts-ignore
import type { PatchedProblemSourceListingRequest } from '../models';
// @ts-ignore
import type { PatchedProblemsImportAttemptRequest } from '../models';
// @ts-ignore
import type { PatchedProblemsImportCandidateRequest } from '../models';
// @ts-ignore
import type { PatchedProblemsImportImageRequest } from '../models';
// @ts-ignore
import type { PatchedVideoRequest } from '../models';
// @ts-ignore
import type { ProblemBoardsRequestRequest } from '../models';
// @ts-ignore
import type { ProblemSourceGenerator } from '../models';
// @ts-ignore
import type { ProblemSourceListing } from '../models';
// @ts-ignore
import type { ProblemSourceListingRequest } from '../models';
// @ts-ignore
import type { ProblemsImportAttempt } from '../models';
// @ts-ignore
import type { ProblemsImportAttemptCreateRequestRequest } from '../models';
// @ts-ignore
import type { ProblemsImportAttemptRequest } from '../models';
// @ts-ignore
import type { ProblemsImportCandidate } from '../models';
// @ts-ignore
import type { ProblemsImportCandidateRequest } from '../models';
// @ts-ignore
import type { ProblemsImportImage } from '../models';
// @ts-ignore
import type { ProblemsImportImageRequest } from '../models';
// @ts-ignore
import type { ReviewStatusCdfEnum } from '../models';
// @ts-ignore
import type { Subject } from '../models';
// @ts-ignore
import type { SubjectNavigationItems } from '../models';
// @ts-ignore
import type { TrelloBoardsResponse } from '../models';
// @ts-ignore
import type { UserRequest } from '../models';
// @ts-ignore
import type { Video } from '../models';
// @ts-ignore
import type { VideoCreateRequest } from '../models';
// @ts-ignore
import type { VideoRequest } from '../models';
/**
 * BackofficeApi - axios parameter creator
 * @export
 */
export const BackofficeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BankAccountRequest} bankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingCreate: async (bankAccountRequest: BankAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankAccountRequest' is not null or undefined
            assertParamExists('backofficeApiAccountingCreate', 'bankAccountRequest', bankAccountRequest)
            const localVarPath = `/wt/backoffice/api/accounting/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this bank account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiAccountingDestroy', 'id', id)
            const localVarPath = `/wt/backoffice/api/accounting/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/accounting/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this bank account.
         * @param {PatchedBankAccountRequest} [patchedBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingPartialUpdate: async (id: number, patchedBankAccountRequest?: PatchedBankAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiAccountingPartialUpdate', 'id', id)
            const localVarPath = `/wt/backoffice/api/accounting/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedBankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this bank account.
         * @param {BankAccountRequest} bankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingUpdate: async (id: number, bankAccountRequest: BankAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiAccountingUpdate', 'id', id)
            // verify required parameter 'bankAccountRequest' is not null or undefined
            assertParamExists('backofficeApiAccountingUpdate', 'bankAccountRequest', bankAccountRequest)
            const localVarPath = `/wt/backoffice/api/accounting/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {CustomDocumentRequest} [file] 
         * @param {UserRequest} [user] 
         * @param {AssignmentAttemptReviewStatusEnum} [reviewStatus] 
         * @param {boolean} [isReviewSentToUser] 
         * @param {number} [assignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAssignmentAttemptPartialUpdate: async (id: number, file?: CustomDocumentRequest, user?: UserRequest, reviewStatus?: AssignmentAttemptReviewStatusEnum, isReviewSentToUser?: boolean, assignment?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiAssignmentAttemptPartialUpdate', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (user !== undefined) { 
                localVarFormParams.append('user', new Blob([JSON.stringify(user)], { type: "application/json", }));
            }
    
            if (reviewStatus !== undefined) { 
                localVarFormParams.append('review_status', reviewStatus as any);
            }
    
            if (isReviewSentToUser !== undefined) { 
                localVarFormParams.append('is_review_sent_to_user', String(isReviewSentToUser) as any);
            }
    
            if (assignment !== undefined) { 
                localVarFormParams.append('assignment', assignment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAssignmentReviewDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiAssignmentReviewDestroy', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-review/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {AssignmentReviewerRequest} reviewer 
         * @param {CustomDocumentRequest} file 
         * @param {number} assignmentAttempt 
         * @param {ReviewStatusCdfEnum} [reviewStatus] 
         * @param {string | null} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAssignmentReviewUpdate: async (id: number, reviewer: AssignmentReviewerRequest, file: CustomDocumentRequest, assignmentAttempt: number, reviewStatus?: ReviewStatusCdfEnum, comment?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiAssignmentReviewUpdate', 'id', id)
            // verify required parameter 'reviewer' is not null or undefined
            assertParamExists('backofficeApiAssignmentReviewUpdate', 'reviewer', reviewer)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('backofficeApiAssignmentReviewUpdate', 'file', file)
            // verify required parameter 'assignmentAttempt' is not null or undefined
            assertParamExists('backofficeApiAssignmentReviewUpdate', 'assignmentAttempt', assignmentAttempt)
            const localVarPath = `/wt/backoffice/api/assignment-review/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (reviewer !== undefined) { 
                localVarFormParams.append('reviewer', new Blob([JSON.stringify(reviewer)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (reviewStatus !== undefined) { 
                localVarFormParams.append('review_status', reviewStatus as any);
            }
    
            if (comment !== undefined) { 
                localVarFormParams.append('comment', comment as any);
            }
    
            if (assignmentAttempt !== undefined) { 
                localVarFormParams.append('assignment_attempt', assignmentAttempt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemSourceListingRequest} problemSourceListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingCreate: async (problemSourceListingRequest: ProblemSourceListingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemSourceListingRequest' is not null or undefined
            assertParamExists('backofficeApiProblemSourceListingCreate', 'problemSourceListingRequest', problemSourceListingRequest)
            const localVarPath = `/wt/backoffice/api/problem-source-listing/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemSourceListingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemSourceListingDestroy', 'id', id)
            const localVarPath = `/wt/backoffice/api/problem-source-listing/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {PatchedProblemSourceListingRequest} [patchedProblemSourceListingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingPartialUpdate: async (id: number, patchedProblemSourceListingRequest?: PatchedProblemSourceListingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemSourceListingPartialUpdate', 'id', id)
            const localVarPath = `/wt/backoffice/api/problem-source-listing/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProblemSourceListingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {ProblemSourceListingRequest} problemSourceListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingUpdate: async (id: number, problemSourceListingRequest: ProblemSourceListingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemSourceListingUpdate', 'id', id)
            // verify required parameter 'problemSourceListingRequest' is not null or undefined
            assertParamExists('backofficeApiProblemSourceListingUpdate', 'problemSourceListingRequest', problemSourceListingRequest)
            const localVarPath = `/wt/backoffice/api/problem-source-listing/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemSourceListingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemsImportAttemptDestroy', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/problems-import-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {PatchedProblemsImportAttemptRequest} [patchedProblemsImportAttemptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptPartialUpdate: async (id: number, patchedProblemsImportAttemptRequest?: PatchedProblemsImportAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemsImportAttemptPartialUpdate', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProblemsImportAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemsImportAttemptRetrieve', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {ProblemsImportAttemptRequest} problemsImportAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptUpdate: async (id: number, problemsImportAttemptRequest: ProblemsImportAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemsImportAttemptUpdate', 'id', id)
            // verify required parameter 'problemsImportAttemptRequest' is not null or undefined
            assertParamExists('backofficeApiProblemsImportAttemptUpdate', 'problemsImportAttemptRequest', problemsImportAttemptRequest)
            const localVarPath = `/wt/backoffice/api/problems-import-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemsImportAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {ProblemsImportCandidateRequest} problemsImportCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportCandidateUpdate: async (id: number, problemsImportCandidateRequest: ProblemsImportCandidateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemsImportCandidateUpdate', 'id', id)
            // verify required parameter 'problemsImportCandidateRequest' is not null or undefined
            assertParamExists('backofficeApiProblemsImportCandidateUpdate', 'problemsImportCandidateRequest', problemsImportCandidateRequest)
            const localVarPath = `/wt/backoffice/api/problems-import-candidate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemsImportCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemsImportImageRequest} problemsImportImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportImageCreate: async (problemsImportImageRequest: ProblemsImportImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemsImportImageRequest' is not null or undefined
            assertParamExists('backofficeApiProblemsImportImageCreate', 'problemsImportImageRequest', problemsImportImageRequest)
            const localVarPath = `/wt/backoffice/api/problems-import-image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemsImportImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {ProblemsImportImageRequest} problemsImportImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportImageUpdate: async (id: number, problemsImportImageRequest: ProblemsImportImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiProblemsImportImageUpdate', 'id', id)
            // verify required parameter 'problemsImportImageRequest' is not null or undefined
            assertParamExists('backofficeApiProblemsImportImageUpdate', 'problemsImportImageRequest', problemsImportImageRequest)
            const localVarPath = `/wt/backoffice/api/problems-import-image/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemsImportImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiVideoDestroy', 'id', id)
            const localVarPath = `/wt/backoffice/api/video/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/video/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedVideoRequest} [patchedVideoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoPartialUpdate: async (id: string, patchedVideoRequest?: PatchedVideoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiVideoPartialUpdate', 'id', id)
            const localVarPath = `/wt/backoffice/api/video/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedVideoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiVideoRetrieve', 'id', id)
            const localVarPath = `/wt/backoffice/api/video/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {VideoRequest} videoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoUpdate: async (id: string, videoRequest: VideoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backofficeApiVideoUpdate', 'id', id)
            // verify required parameter 'videoRequest' is not null or undefined
            assertParamExists('backofficeApiVideoUpdate', 'videoRequest', videoRequest)
            const localVarPath = `/wt/backoffice/api/video/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomDocumentRequest} file 
         * @param {UserRequest} user 
         * @param {AssignmentAttemptReviewStatusEnum} reviewStatus 
         * @param {number} assignment 
         * @param {boolean} [isReviewSentToUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignmentAttempt: async (file: CustomDocumentRequest, user: UserRequest, reviewStatus: AssignmentAttemptReviewStatusEnum, assignment: number, isReviewSentToUser?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createAssignmentAttempt', 'file', file)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createAssignmentAttempt', 'user', user)
            // verify required parameter 'reviewStatus' is not null or undefined
            assertParamExists('createAssignmentAttempt', 'reviewStatus', reviewStatus)
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('createAssignmentAttempt', 'assignment', assignment)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (user !== undefined) { 
                localVarFormParams.append('user', new Blob([JSON.stringify(user)], { type: "application/json", }));
            }
    
            if (reviewStatus !== undefined) { 
                localVarFormParams.append('review_status', reviewStatus as any);
            }
    
            if (isReviewSentToUser !== undefined) { 
                localVarFormParams.append('is_review_sent_to_user', String(isReviewSentToUser) as any);
            }
    
            if (assignment !== undefined) { 
                localVarFormParams.append('assignment', assignment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} reviewer 
         * @param {number} assignmentAttempt 
         * @param {ReviewStatusCdfEnum} [reviewStatus] 
         * @param {string | null} [comment] 
         * @param {number | null} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignmentReview: async (reviewer: number, assignmentAttempt: number, reviewStatus?: ReviewStatusCdfEnum, comment?: string | null, file?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewer' is not null or undefined
            assertParamExists('createAssignmentReview', 'reviewer', reviewer)
            // verify required parameter 'assignmentAttempt' is not null or undefined
            assertParamExists('createAssignmentReview', 'assignmentAttempt', assignmentAttempt)
            const localVarPath = `/wt/backoffice/api/assignment-review/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (reviewStatus !== undefined) { 
                localVarFormParams.append('review_status', reviewStatus as any);
            }
    
            if (comment !== undefined) { 
                localVarFormParams.append('comment', comment as any);
            }
    
            if (reviewer !== undefined) { 
                localVarFormParams.append('reviewer', reviewer as any);
            }
    
            if (assignmentAttempt !== undefined) { 
                localVarFormParams.append('assignment_attempt', assignmentAttempt as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemsImportAttemptCreateRequestRequest} problemsImportAttemptCreateRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProblemsImportAttempt: async (problemsImportAttemptCreateRequestRequest: ProblemsImportAttemptCreateRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemsImportAttemptCreateRequestRequest' is not null or undefined
            assertParamExists('createProblemsImportAttempt', 'problemsImportAttemptCreateRequestRequest', problemsImportAttemptCreateRequestRequest)
            const localVarPath = `/wt/backoffice/api/problems-import-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemsImportAttemptCreateRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemsImportCandidateRequest} problemsImportCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProblemsImportCandidate: async (problemsImportCandidateRequest: ProblemsImportCandidateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemsImportCandidateRequest' is not null or undefined
            assertParamExists('createProblemsImportCandidate', 'problemsImportCandidateRequest', problemsImportCandidateRequest)
            const localVarPath = `/wt/backoffice/api/problems-import-candidate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemsImportCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VideoCreateRequest} videoCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo: async (videoCreateRequest: VideoCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videoCreateRequest' is not null or undefined
            assertParamExists('createVideo', 'videoCreateRequest', videoCreateRequest)
            const localVarPath = `/wt/backoffice/api/video/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignmentAttempt: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAssignmentAttempt', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProblemsImportCandidate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProblemsImportCandidate', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-candidate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProblemsImportImage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProblemsImportImage', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-image/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemBoardsRequestRequest} problemBoardsRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBoards: async (problemBoardsRequestRequest: ProblemBoardsRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemBoardsRequestRequest' is not null or undefined
            assertParamExists('generateBoards', 'problemBoardsRequestRequest', problemBoardsRequestRequest)
            const localVarPath = `/wt/backoffice/api/problem-boards/generate_boards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemBoardsRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProblemCandidates: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateProblemCandidates', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import/{id}/generate_problem_candidates/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateProblemSourcesRequestRequest} generateProblemSourcesRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProblemSources: async (generateProblemSourcesRequestRequest: GenerateProblemSourcesRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateProblemSourcesRequestRequest' is not null or undefined
            assertParamExists('generateProblemSources', 'generateProblemSourcesRequestRequest', generateProblemSourcesRequestRequest)
            const localVarPath = `/wt/backoffice/api/problem-source-generator/generate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateProblemSourcesRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPageCacheKeyBases: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/page-cache-key/all-page-cache-key-bases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignment: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssignment', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentAttempt: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssignmentAttempt', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [assignment] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentAttemptList: async (assignment?: number, limit?: number, offset?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/assignment-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assignment !== undefined) {
                localVarQueryParameter['assignment'] = assignment;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/assignment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentReview: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssignmentReview', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-review/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentReviewList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/assignment-review/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank account data
         * @param {number} id A unique integer value identifying this bank account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountData: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankAccountData', 'id', id)
            const localVarPath = `/wt/backoffice/api/accounting/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGocardlessAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/gocardless/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGocardlessAuthLink: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/gocardless/auth-link/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGocardlessInstitutions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/gocardless/institutions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleDriveFolders: async (parentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/google-drive/folders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUserAttempt: async (assignmentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('getLatestUserAttempt', 'assignmentId', assignmentId)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/latest-user-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (assignmentId !== undefined) { 
                localVarFormParams.append('assignment_id', assignmentId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationItems: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/backoffice-layout/navigation-items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notion entries
         * @param {number} id A unique integer value identifying this bank account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotionEntries: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotionEntries', 'id', id)
            const localVarPath = `/wt/backoffice/api/accounting/{id}/notion-entries/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source generator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceGenrator: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblemSourceGenrator', 'id', id)
            const localVarPath = `/wt/backoffice/api/problem-source-generator/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceGenratorList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/problem-source-generator/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceListing: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblemSourceListing', 'id', id)
            const localVarPath = `/wt/backoffice/api/problem-source-listing/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceListingList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/problem-source-listing/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [problemSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceSectionList: async (limit?: number, offset?: number, problemSource?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/problem-source-section/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (problemSource !== undefined) {
                localVarQueryParameter['problem_source'] = problemSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportCandidate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblemsImportCandidate', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-candidate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} problemSource 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {GetProblemsImportCandidateListStatusEnum} [status] 
         * @param {string} [statusIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportCandidateList: async (problemSource: number, limit?: number, offset?: number, status?: GetProblemsImportCandidateListStatusEnum, statusIn?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemSource' is not null or undefined
            assertParamExists('getProblemsImportCandidateList', 'problemSource', problemSource)
            const localVarPath = `/wt/backoffice/api/problems-import-candidate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (problemSource !== undefined) {
                localVarQueryParameter['problem_source'] = problemSource;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statusIn !== undefined) {
                localVarQueryParameter['status__in'] = statusIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportImage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblemsImportImage', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-image/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} problemSource 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {GetProblemsImportImageListStatusEnum} [status] 
         * @param {string} [statusIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportImageList: async (problemSource: number, limit?: number, offset?: number, status?: GetProblemsImportImageListStatusEnum, statusIn?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemSource' is not null or undefined
            assertParamExists('getProblemsImportImageList', 'problemSource', problemSource)
            const localVarPath = `/wt/backoffice/api/problems-import-image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (problemSource !== undefined) {
                localVarQueryParameter['problem_source'] = problemSource;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statusIn !== undefined) {
                localVarQueryParameter['status__in'] = statusIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subject.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubject: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubject', 'id', id)
            const localVarPath = `/wt/backoffice/api/subject/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/subject/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrelloBoards: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/trello/boards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrelloWorkspaces: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/backoffice/api/trello/workspaces/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitiatePdfPrintRequestRequest} initiatePdfPrintRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePdfPrint: async (initiatePdfPrintRequestRequest: InitiatePdfPrintRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiatePdfPrintRequestRequest' is not null or undefined
            assertParamExists('initiatePdfPrint', 'initiatePdfPrintRequestRequest', initiatePdfPrintRequestRequest)
            const localVarPath = `/wt/backoffice/api/pdf-print/initiate-pdf-print/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiatePdfPrintRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapSolutionsToProblems: async (id: number, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mapSolutionsToProblems', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('mapSolutionsToProblems', 'file', file)
            const localVarPath = `/wt/backoffice/api/problems-import/{id}/map_solutions_to_problems/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh bank account data
         * @param {number} id A unique integer value identifying this bank account.
         * @param {boolean} [balances] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [details] 
         * @param {boolean} [metaData] 
         * @param {boolean} [transactions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBankAccountData: async (id: number, balances?: boolean, dateFrom?: string, dateTo?: string, details?: boolean, metaData?: boolean, transactions?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshBankAccountData', 'id', id)
            const localVarPath = `/wt/backoffice/api/accounting/{id}/refresh/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (balances !== undefined) {
                localVarQueryParameter['balances'] = balances;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            if (details !== undefined) {
                localVarQueryParameter['details'] = details;
            }

            if (metaData !== undefined) {
                localVarQueryParameter['meta_data'] = metaData;
            }

            if (transactions !== undefined) {
                localVarQueryParameter['transactions'] = transactions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {Array<string> | null} [emails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAssignmentAttemptEmail: async (id: number, emails?: Array<string> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendAssignmentAttemptEmail', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/{id}/send-email/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emails) {
                localVarFormParams.append('emails', emails.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GocardlessSetAccountsRequestRequest} gocardlessSetAccountsRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGocardlessAccount: async (gocardlessSetAccountsRequestRequest: GocardlessSetAccountsRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gocardlessSetAccountsRequestRequest' is not null or undefined
            assertParamExists('setGocardlessAccount', 'gocardlessSetAccountsRequestRequest', gocardlessSetAccountsRequestRequest)
            const localVarPath = `/wt/backoffice/api/gocardless/set-account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gocardlessSetAccountsRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GocardlessSetInstitutionRequestRequest} gocardlessSetInstitutionRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGocardlessInstitution: async (gocardlessSetInstitutionRequestRequest: GocardlessSetInstitutionRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gocardlessSetInstitutionRequestRequest' is not null or undefined
            assertParamExists('setGocardlessInstitution', 'gocardlessSetInstitutionRequestRequest', gocardlessSetInstitutionRequestRequest)
            const localVarPath = `/wt/backoffice/api/gocardless/set-institution/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gocardlessSetInstitutionRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProblemsImportProcess: async (id: number, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startProblemsImportProcess', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('startProblemsImportProcess', 'file', file)
            const localVarPath = `/wt/backoffice/api/problems-import/{id}/start_problems_import_process/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {number} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAssignmentAttempt: async (file: File, assignmentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('submitAssignmentAttempt', 'file', file)
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('submitAssignmentAttempt', 'assignmentId', assignmentId)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/submit-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (assignmentId !== undefined) { 
                localVarFormParams.append('assignment_id', assignmentId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {CustomDocumentRequest} file 
         * @param {UserRequest} user 
         * @param {AssignmentAttemptReviewStatusEnum} reviewStatus 
         * @param {number} assignment 
         * @param {boolean} [isReviewSentToUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentAttempt: async (id: number, file: CustomDocumentRequest, user: UserRequest, reviewStatus: AssignmentAttemptReviewStatusEnum, assignment: number, isReviewSentToUser?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAssignmentAttempt', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateAssignmentAttempt', 'file', file)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateAssignmentAttempt', 'user', user)
            // verify required parameter 'reviewStatus' is not null or undefined
            assertParamExists('updateAssignmentAttempt', 'reviewStatus', reviewStatus)
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('updateAssignmentAttempt', 'assignment', assignment)
            const localVarPath = `/wt/backoffice/api/assignment-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (user !== undefined) { 
                localVarFormParams.append('user', new Blob([JSON.stringify(user)], { type: "application/json", }));
            }
    
            if (reviewStatus !== undefined) { 
                localVarFormParams.append('review_status', reviewStatus as any);
            }
    
            if (isReviewSentToUser !== undefined) { 
                localVarFormParams.append('is_review_sent_to_user', String(isReviewSentToUser) as any);
            }
    
            if (assignment !== undefined) { 
                localVarFormParams.append('assignment', assignment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {File} [file] 
         * @param {string} [reviewStatus] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentReview: async (id: number, file?: File, reviewStatus?: string, comment?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAssignmentReview', 'id', id)
            const localVarPath = `/wt/backoffice/api/assignment-review/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (reviewStatus !== undefined) { 
                localVarFormParams.append('review_status', reviewStatus as any);
            }
    
            if (comment !== undefined) { 
                localVarFormParams.append('comment', comment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {PatchedProblemsImportCandidateRequest} [patchedProblemsImportCandidateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProblemsImportCandidate: async (id: number, patchedProblemsImportCandidateRequest?: PatchedProblemsImportCandidateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProblemsImportCandidate', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-candidate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProblemsImportCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {PatchedProblemsImportImageRequest} [patchedProblemsImportImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProblemsImportImage: async (id: number, patchedProblemsImportImageRequest?: PatchedProblemsImportImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProblemsImportImage', 'id', id)
            const localVarPath = `/wt/backoffice/api/problems-import-image/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProblemsImportImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeApi - functional programming interface
 * @export
 */
export const BackofficeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackofficeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BankAccountRequest} bankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAccountingCreate(bankAccountRequest: BankAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAccountingCreate(bankAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAccountingCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this bank account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAccountingDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAccountingDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAccountingDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAccountingList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBankAccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAccountingList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAccountingList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this bank account.
         * @param {PatchedBankAccountRequest} [patchedBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAccountingPartialUpdate(id: number, patchedBankAccountRequest?: PatchedBankAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAccountingPartialUpdate(id, patchedBankAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAccountingPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this bank account.
         * @param {BankAccountRequest} bankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAccountingUpdate(id: number, bankAccountRequest: BankAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAccountingUpdate(id, bankAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAccountingUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {CustomDocumentRequest} [file] 
         * @param {UserRequest} [user] 
         * @param {AssignmentAttemptReviewStatusEnum} [reviewStatus] 
         * @param {boolean} [isReviewSentToUser] 
         * @param {number} [assignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAssignmentAttemptPartialUpdate(id: number, file?: CustomDocumentRequest, user?: UserRequest, reviewStatus?: AssignmentAttemptReviewStatusEnum, isReviewSentToUser?: boolean, assignment?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAssignmentAttemptPartialUpdate(id, file, user, reviewStatus, isReviewSentToUser, assignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAssignmentAttemptPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAssignmentReviewDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAssignmentReviewDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAssignmentReviewDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {AssignmentReviewerRequest} reviewer 
         * @param {CustomDocumentRequest} file 
         * @param {number} assignmentAttempt 
         * @param {ReviewStatusCdfEnum} [reviewStatus] 
         * @param {string | null} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiAssignmentReviewUpdate(id: number, reviewer: AssignmentReviewerRequest, file: CustomDocumentRequest, assignmentAttempt: number, reviewStatus?: ReviewStatusCdfEnum, comment?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiAssignmentReviewUpdate(id, reviewer, file, assignmentAttempt, reviewStatus, comment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiAssignmentReviewUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemSourceListingRequest} problemSourceListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemSourceListingCreate(problemSourceListingRequest: ProblemSourceListingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemSourceListingCreate(problemSourceListingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemSourceListingCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemSourceListingDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemSourceListingDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemSourceListingDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {PatchedProblemSourceListingRequest} [patchedProblemSourceListingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemSourceListingPartialUpdate(id: number, patchedProblemSourceListingRequest?: PatchedProblemSourceListingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemSourceListingPartialUpdate(id, patchedProblemSourceListingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemSourceListingPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {ProblemSourceListingRequest} problemSourceListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemSourceListingUpdate(id: number, problemSourceListingRequest: ProblemSourceListingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemSourceListingUpdate(id, problemSourceListingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemSourceListingUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportAttemptDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportAttemptDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportAttemptDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportAttemptList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemsImportAttemptList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportAttemptList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportAttemptList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {PatchedProblemsImportAttemptRequest} [patchedProblemsImportAttemptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportAttemptPartialUpdate(id: number, patchedProblemsImportAttemptRequest?: PatchedProblemsImportAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportAttemptPartialUpdate(id, patchedProblemsImportAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportAttemptPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportAttemptRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportAttemptRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportAttemptRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problems import attempt.
         * @param {ProblemsImportAttemptRequest} problemsImportAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportAttemptUpdate(id: number, problemsImportAttemptRequest: ProblemsImportAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportAttemptUpdate(id, problemsImportAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportAttemptUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {ProblemsImportCandidateRequest} problemsImportCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportCandidateUpdate(id: number, problemsImportCandidateRequest: ProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportCandidate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportCandidateUpdate(id, problemsImportCandidateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportCandidateUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemsImportImageRequest} problemsImportImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportImageCreate(problemsImportImageRequest: ProblemsImportImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportImageCreate(problemsImportImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportImageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {ProblemsImportImageRequest} problemsImportImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiProblemsImportImageUpdate(id: number, problemsImportImageRequest: ProblemsImportImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiProblemsImportImageUpdate(id, problemsImportImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiProblemsImportImageUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiVideoDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiVideoDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiVideoDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiVideoList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedVideoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiVideoList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiVideoList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedVideoRequest} [patchedVideoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiVideoPartialUpdate(id: string, patchedVideoRequest?: PatchedVideoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiVideoPartialUpdate(id, patchedVideoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiVideoPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiVideoRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiVideoRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiVideoRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {VideoRequest} videoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backofficeApiVideoUpdate(id: string, videoRequest: VideoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backofficeApiVideoUpdate(id, videoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.backofficeApiVideoUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CustomDocumentRequest} file 
         * @param {UserRequest} user 
         * @param {AssignmentAttemptReviewStatusEnum} reviewStatus 
         * @param {number} assignment 
         * @param {boolean} [isReviewSentToUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssignmentAttempt(file: CustomDocumentRequest, user: UserRequest, reviewStatus: AssignmentAttemptReviewStatusEnum, assignment: number, isReviewSentToUser?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignmentAttempt(file, user, reviewStatus, assignment, isReviewSentToUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.createAssignmentAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} reviewer 
         * @param {number} assignmentAttempt 
         * @param {ReviewStatusCdfEnum} [reviewStatus] 
         * @param {string | null} [comment] 
         * @param {number | null} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssignmentReview(reviewer: number, assignmentAttempt: number, reviewStatus?: ReviewStatusCdfEnum, comment?: string | null, file?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignmentReview(reviewer, assignmentAttempt, reviewStatus, comment, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.createAssignmentReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemsImportAttemptCreateRequestRequest} problemsImportAttemptCreateRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProblemsImportAttempt(problemsImportAttemptCreateRequestRequest: ProblemsImportAttemptCreateRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProblemsImportAttempt(problemsImportAttemptCreateRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.createProblemsImportAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemsImportCandidateRequest} problemsImportCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProblemsImportCandidate(problemsImportCandidateRequest: ProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportCandidate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProblemsImportCandidate(problemsImportCandidateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.createProblemsImportCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VideoCreateRequest} videoCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVideo(videoCreateRequest: VideoCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVideo(videoCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.createVideo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignmentAttempt(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignmentAttempt(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.deleteAssignmentAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProblemsImportCandidate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProblemsImportCandidate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.deleteProblemsImportCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProblemsImportImage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProblemsImportImage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.deleteProblemsImportImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemBoardsRequestRequest} problemBoardsRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBoards(problemBoardsRequestRequest: ProblemBoardsRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateBoards(problemBoardsRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.generateBoards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateProblemCandidates(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateProblemCandidates(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.generateProblemCandidates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateProblemSourcesRequestRequest} generateProblemSourcesRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateProblemSources(generateProblemSourcesRequestRequest: GenerateProblemSourcesRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateProblemSourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateProblemSources(generateProblemSourcesRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.generateProblemSources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPageCacheKeyBases(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPageCacheKeyBaseList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPageCacheKeyBases(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAllPageCacheKeyBases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignment(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentAttempt(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentAttempt(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAssignmentAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [assignment] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentAttemptList(assignment?: number, limit?: number, offset?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAssignmentAttemptList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentAttemptList(assignment, limit, offset, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAssignmentAttemptList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAssignmentListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAssignmentList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentReview(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentReview(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAssignmentReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentReviewList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAssignmentReviewListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentReviewList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getAssignmentReviewList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get bank account data
         * @param {number} id A unique integer value identifying this bank account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountData(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccountData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getBankAccountData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGocardlessAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GocardlessGetAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGocardlessAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getGocardlessAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGocardlessAuthLink(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GocardlessAuthLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGocardlessAuthLink(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getGocardlessAuthLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGocardlessInstitutions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GocardlessGetInstitutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGocardlessInstitutions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getGocardlessInstitutions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleDriveFolders(parentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleDriveFoldersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleDriveFolders(parentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getGoogleDriveFolders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestUserAttempt(assignmentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestUserAttempt(assignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getLatestUserAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNavigationItems(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubjectNavigationItems>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNavigationItems(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getNavigationItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Notion entries
         * @param {number} id A unique integer value identifying this bank account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotionEntries(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountNotionEntries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotionEntries(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getNotionEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source generator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSourceGenrator(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceGenerator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSourceGenrator(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemSourceGenrator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSourceGenratorList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemSourceGeneratorListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSourceGenratorList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemSourceGenratorList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSourceListing(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSourceListing(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemSourceListing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSourceListingList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemSourceListingListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSourceListingList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemSourceListingList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [problemSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSourceSectionList(limit?: number, offset?: number, problemSource?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemSourceSectionListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSourceSectionList(limit, offset, problemSource, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemSourceSectionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemsImportCandidate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportCandidate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemsImportCandidate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemsImportCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} problemSource 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {GetProblemsImportCandidateListStatusEnum} [status] 
         * @param {string} [statusIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemsImportCandidateList(problemSource: number, limit?: number, offset?: number, status?: GetProblemsImportCandidateListStatusEnum, statusIn?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemsImportCandidateList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemsImportCandidateList(problemSource, limit, offset, status, statusIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemsImportCandidateList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemsImportImage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemsImportImage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemsImportImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} problemSource 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {GetProblemsImportImageListStatusEnum} [status] 
         * @param {string} [statusIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemsImportImageList(problemSource: number, limit?: number, offset?: number, status?: GetProblemsImportImageListStatusEnum, statusIn?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemsImportImageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemsImportImageList(problemSource, limit, offset, status, statusIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getProblemsImportImageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subject.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubject(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getSubject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjectList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSubjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubjectList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getSubjectList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrelloBoards(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrelloBoardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrelloBoards(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getTrelloBoards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrelloWorkspaces(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrelloWorkspaces(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.getTrelloWorkspaces']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InitiatePdfPrintRequestRequest} initiatePdfPrintRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePdfPrint(initiatePdfPrintRequestRequest: InitiatePdfPrintRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitiatePdfPrintResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePdfPrint(initiatePdfPrintRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.initiatePdfPrint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapSolutionsToProblems(id: number, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapSolutionsToProblems(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.mapSolutionsToProblems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh bank account data
         * @param {number} id A unique integer value identifying this bank account.
         * @param {boolean} [balances] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [details] 
         * @param {boolean} [metaData] 
         * @param {boolean} [transactions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshBankAccountData(id: number, balances?: boolean, dateFrom?: string, dateTo?: string, details?: boolean, metaData?: boolean, transactions?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshBankAccountData(id, balances, dateFrom, dateTo, details, metaData, transactions, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.refreshBankAccountData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {Array<string> | null} [emails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAssignmentAttemptEmail(id: number, emails?: Array<string> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAssignmentAttemptEmail(id, emails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.sendAssignmentAttemptEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GocardlessSetAccountsRequestRequest} gocardlessSetAccountsRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGocardlessAccount(gocardlessSetAccountsRequestRequest: GocardlessSetAccountsRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGocardlessAccount(gocardlessSetAccountsRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.setGocardlessAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GocardlessSetInstitutionRequestRequest} gocardlessSetInstitutionRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGocardlessInstitution(gocardlessSetInstitutionRequestRequest: GocardlessSetInstitutionRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGocardlessInstitution(gocardlessSetInstitutionRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.setGocardlessInstitution']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startProblemsImportProcess(id: number, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startProblemsImportProcess(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.startProblemsImportProcess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {number} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAssignmentAttempt(file: File, assignmentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitAssignmentAttempt(file, assignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.submitAssignmentAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment attempt.
         * @param {CustomDocumentRequest} file 
         * @param {UserRequest} user 
         * @param {AssignmentAttemptReviewStatusEnum} reviewStatus 
         * @param {number} assignment 
         * @param {boolean} [isReviewSentToUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignmentAttempt(id: number, file: CustomDocumentRequest, user: UserRequest, reviewStatus: AssignmentAttemptReviewStatusEnum, assignment: number, isReviewSentToUser?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignmentAttempt(id, file, user, reviewStatus, assignment, isReviewSentToUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.updateAssignmentAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this assignment review.
         * @param {File} [file] 
         * @param {string} [reviewStatus] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignmentReview(id: number, file?: File, reviewStatus?: string, comment?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignmentReview(id, file, reviewStatus, comment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.updateAssignmentReview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import candidate.
         * @param {PatchedProblemsImportCandidateRequest} [patchedProblemsImportCandidateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProblemsImportCandidate(id: number, patchedProblemsImportCandidateRequest?: PatchedProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportCandidate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProblemsImportCandidate(id, patchedProblemsImportCandidateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.updateProblemsImportCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Problem import image.
         * @param {PatchedProblemsImportImageRequest} [patchedProblemsImportImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProblemsImportImage(id: number, patchedProblemsImportImageRequest?: PatchedProblemsImportImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemsImportImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProblemsImportImage(id, patchedProblemsImportImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackofficeApi.updateProblemsImportImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BackofficeApi - factory interface
 * @export
 */
export const BackofficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackofficeApiFp(configuration)
    return {
        /**
         * 
         * @param {BackofficeApiBackofficeApiAccountingCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingCreate(requestParameters: BackofficeApiBackofficeApiAccountingCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAccount> {
            return localVarFp.backofficeApiAccountingCreate(requestParameters.bankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAccountingDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingDestroy(requestParameters: BackofficeApiBackofficeApiAccountingDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.backofficeApiAccountingDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAccountingListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingList(requestParameters: BackofficeApiBackofficeApiAccountingListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedBankAccountList> {
            return localVarFp.backofficeApiAccountingList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAccountingPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingPartialUpdate(requestParameters: BackofficeApiBackofficeApiAccountingPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAccount> {
            return localVarFp.backofficeApiAccountingPartialUpdate(requestParameters.id, requestParameters.patchedBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAccountingUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAccountingUpdate(requestParameters: BackofficeApiBackofficeApiAccountingUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAccount> {
            return localVarFp.backofficeApiAccountingUpdate(requestParameters.id, requestParameters.bankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAssignmentAttemptPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAssignmentAttemptPartialUpdate(requestParameters: BackofficeApiBackofficeApiAssignmentAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentAttempt> {
            return localVarFp.backofficeApiAssignmentAttemptPartialUpdate(requestParameters.id, requestParameters.file, requestParameters.user, requestParameters.reviewStatus, requestParameters.isReviewSentToUser, requestParameters.assignment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAssignmentReviewDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAssignmentReviewDestroy(requestParameters: BackofficeApiBackofficeApiAssignmentReviewDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.backofficeApiAssignmentReviewDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiAssignmentReviewUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiAssignmentReviewUpdate(requestParameters: BackofficeApiBackofficeApiAssignmentReviewUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentReview> {
            return localVarFp.backofficeApiAssignmentReviewUpdate(requestParameters.id, requestParameters.reviewer, requestParameters.file, requestParameters.assignmentAttempt, requestParameters.reviewStatus, requestParameters.comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemSourceListingCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingCreate(requestParameters: BackofficeApiBackofficeApiProblemSourceListingCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceListing> {
            return localVarFp.backofficeApiProblemSourceListingCreate(requestParameters.problemSourceListingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemSourceListingDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingDestroy(requestParameters: BackofficeApiBackofficeApiProblemSourceListingDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.backofficeApiProblemSourceListingDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemSourceListingPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingPartialUpdate(requestParameters: BackofficeApiBackofficeApiProblemSourceListingPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceListing> {
            return localVarFp.backofficeApiProblemSourceListingPartialUpdate(requestParameters.id, requestParameters.patchedProblemSourceListingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemSourceListingUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemSourceListingUpdate(requestParameters: BackofficeApiBackofficeApiProblemSourceListingUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceListing> {
            return localVarFp.backofficeApiProblemSourceListingUpdate(requestParameters.id, requestParameters.problemSourceListingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportAttemptDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptDestroy(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.backofficeApiProblemsImportAttemptDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportAttemptListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptList(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemsImportAttemptList> {
            return localVarFp.backofficeApiProblemsImportAttemptList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptPartialUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportAttempt> {
            return localVarFp.backofficeApiProblemsImportAttemptPartialUpdate(requestParameters.id, requestParameters.patchedProblemsImportAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportAttemptRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptRetrieve(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportAttempt> {
            return localVarFp.backofficeApiProblemsImportAttemptRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportAttemptUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportAttemptUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportAttempt> {
            return localVarFp.backofficeApiProblemsImportAttemptUpdate(requestParameters.id, requestParameters.problemsImportAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportCandidateUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportCandidateUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportCandidateUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportCandidate> {
            return localVarFp.backofficeApiProblemsImportCandidateUpdate(requestParameters.id, requestParameters.problemsImportCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportImageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportImageCreate(requestParameters: BackofficeApiBackofficeApiProblemsImportImageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportImage> {
            return localVarFp.backofficeApiProblemsImportImageCreate(requestParameters.problemsImportImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiProblemsImportImageUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiProblemsImportImageUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportImageUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportImage> {
            return localVarFp.backofficeApiProblemsImportImageUpdate(requestParameters.id, requestParameters.problemsImportImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiVideoDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoDestroy(requestParameters: BackofficeApiBackofficeApiVideoDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.backofficeApiVideoDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiVideoListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoList(requestParameters: BackofficeApiBackofficeApiVideoListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedVideoList> {
            return localVarFp.backofficeApiVideoList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiVideoPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoPartialUpdate(requestParameters: BackofficeApiBackofficeApiVideoPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Video> {
            return localVarFp.backofficeApiVideoPartialUpdate(requestParameters.id, requestParameters.patchedVideoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiVideoRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoRetrieve(requestParameters: BackofficeApiBackofficeApiVideoRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Video> {
            return localVarFp.backofficeApiVideoRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiBackofficeApiVideoUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backofficeApiVideoUpdate(requestParameters: BackofficeApiBackofficeApiVideoUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Video> {
            return localVarFp.backofficeApiVideoUpdate(requestParameters.id, requestParameters.videoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiCreateAssignmentAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignmentAttempt(requestParameters: BackofficeApiCreateAssignmentAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentAttempt> {
            return localVarFp.createAssignmentAttempt(requestParameters.file, requestParameters.user, requestParameters.reviewStatus, requestParameters.assignment, requestParameters.isReviewSentToUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiCreateAssignmentReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignmentReview(requestParameters: BackofficeApiCreateAssignmentReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentReview> {
            return localVarFp.createAssignmentReview(requestParameters.reviewer, requestParameters.assignmentAttempt, requestParameters.reviewStatus, requestParameters.comment, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiCreateProblemsImportAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProblemsImportAttempt(requestParameters: BackofficeApiCreateProblemsImportAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportAttempt> {
            return localVarFp.createProblemsImportAttempt(requestParameters.problemsImportAttemptCreateRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiCreateProblemsImportCandidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProblemsImportCandidate(requestParameters: BackofficeApiCreateProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportCandidate> {
            return localVarFp.createProblemsImportCandidate(requestParameters.problemsImportCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiCreateVideoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideo(requestParameters: BackofficeApiCreateVideoRequest, options?: RawAxiosRequestConfig): AxiosPromise<Video> {
            return localVarFp.createVideo(requestParameters.videoCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiDeleteAssignmentAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignmentAttempt(requestParameters: BackofficeApiDeleteAssignmentAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAssignmentAttempt(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiDeleteProblemsImportCandidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProblemsImportCandidate(requestParameters: BackofficeApiDeleteProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProblemsImportCandidate(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiDeleteProblemsImportImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProblemsImportImage(requestParameters: BackofficeApiDeleteProblemsImportImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProblemsImportImage(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGenerateBoardsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBoards(requestParameters: BackofficeApiGenerateBoardsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.generateBoards(requestParameters.problemBoardsRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGenerateProblemCandidatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProblemCandidates(requestParameters: BackofficeApiGenerateProblemCandidatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.generateProblemCandidates(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGenerateProblemSourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProblemSources(requestParameters: BackofficeApiGenerateProblemSourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateProblemSourcesResponse> {
            return localVarFp.generateProblemSources(requestParameters.generateProblemSourcesRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAllPageCacheKeyBasesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPageCacheKeyBases(requestParameters: BackofficeApiGetAllPageCacheKeyBasesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedPageCacheKeyBaseList> {
            return localVarFp.getAllPageCacheKeyBases(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAssignmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignment(requestParameters: BackofficeApiGetAssignmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.getAssignment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAssignmentAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentAttempt(requestParameters: BackofficeApiGetAssignmentAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentAttempt> {
            return localVarFp.getAssignmentAttempt(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAssignmentAttemptListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentAttemptList(requestParameters: BackofficeApiGetAssignmentAttemptListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAssignmentAttemptList> {
            return localVarFp.getAssignmentAttemptList(requestParameters.assignment, requestParameters.limit, requestParameters.offset, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAssignmentListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentList(requestParameters: BackofficeApiGetAssignmentListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAssignmentListList> {
            return localVarFp.getAssignmentList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAssignmentReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentReview(requestParameters: BackofficeApiGetAssignmentReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentReview> {
            return localVarFp.getAssignmentReview(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetAssignmentReviewListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentReviewList(requestParameters: BackofficeApiGetAssignmentReviewListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAssignmentReviewListList> {
            return localVarFp.getAssignmentReviewList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank account data
         * @param {BackofficeApiGetBankAccountDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountData(requestParameters: BackofficeApiGetBankAccountDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAccount> {
            return localVarFp.getBankAccountData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGocardlessAccounts(options?: RawAxiosRequestConfig): AxiosPromise<GocardlessGetAccountsResponse> {
            return localVarFp.getGocardlessAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGocardlessAuthLink(options?: RawAxiosRequestConfig): AxiosPromise<GocardlessAuthLinkResponse> {
            return localVarFp.getGocardlessAuthLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGocardlessInstitutions(options?: RawAxiosRequestConfig): AxiosPromise<GocardlessGetInstitutionResponse> {
            return localVarFp.getGocardlessInstitutions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetGoogleDriveFoldersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleDriveFolders(requestParameters: BackofficeApiGetGoogleDriveFoldersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GoogleDriveFoldersResponse> {
            return localVarFp.getGoogleDriveFolders(requestParameters.parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetLatestUserAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUserAttempt(requestParameters: BackofficeApiGetLatestUserAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentAttempt> {
            return localVarFp.getLatestUserAttempt(requestParameters.assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationItems(options?: RawAxiosRequestConfig): AxiosPromise<Array<SubjectNavigationItems>> {
            return localVarFp.getNavigationItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notion entries
         * @param {BackofficeApiGetNotionEntriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotionEntries(requestParameters: BackofficeApiGetNotionEntriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAccountNotionEntries> {
            return localVarFp.getNotionEntries(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemSourceGenratorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceGenrator(requestParameters: BackofficeApiGetProblemSourceGenratorRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceGenerator> {
            return localVarFp.getProblemSourceGenrator(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemSourceGenratorListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceGenratorList(requestParameters: BackofficeApiGetProblemSourceGenratorListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemSourceGeneratorListList> {
            return localVarFp.getProblemSourceGenratorList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemSourceListingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceListing(requestParameters: BackofficeApiGetProblemSourceListingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceListing> {
            return localVarFp.getProblemSourceListing(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemSourceListingListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceListingList(requestParameters: BackofficeApiGetProblemSourceListingListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemSourceListingListList> {
            return localVarFp.getProblemSourceListingList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemSourceSectionListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceSectionList(requestParameters: BackofficeApiGetProblemSourceSectionListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemSourceSectionListList> {
            return localVarFp.getProblemSourceSectionList(requestParameters.limit, requestParameters.offset, requestParameters.problemSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemsImportCandidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportCandidate(requestParameters: BackofficeApiGetProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportCandidate> {
            return localVarFp.getProblemsImportCandidate(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemsImportCandidateListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportCandidateList(requestParameters: BackofficeApiGetProblemsImportCandidateListRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemsImportCandidateList> {
            return localVarFp.getProblemsImportCandidateList(requestParameters.problemSource, requestParameters.limit, requestParameters.offset, requestParameters.status, requestParameters.statusIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemsImportImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportImage(requestParameters: BackofficeApiGetProblemsImportImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportImage> {
            return localVarFp.getProblemsImportImage(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetProblemsImportImageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemsImportImageList(requestParameters: BackofficeApiGetProblemsImportImageListRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemsImportImageList> {
            return localVarFp.getProblemsImportImageList(requestParameters.problemSource, requestParameters.limit, requestParameters.offset, requestParameters.status, requestParameters.statusIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetSubjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubject(requestParameters: BackofficeApiGetSubjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<Subject> {
            return localVarFp.getSubject(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiGetSubjectListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectList(requestParameters: BackofficeApiGetSubjectListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSubjectList> {
            return localVarFp.getSubjectList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrelloBoards(options?: RawAxiosRequestConfig): AxiosPromise<TrelloBoardsResponse> {
            return localVarFp.getTrelloBoards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrelloWorkspaces(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getTrelloWorkspaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiInitiatePdfPrintRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePdfPrint(requestParameters: BackofficeApiInitiatePdfPrintRequest, options?: RawAxiosRequestConfig): AxiosPromise<InitiatePdfPrintResponse> {
            return localVarFp.initiatePdfPrint(requestParameters.initiatePdfPrintRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiMapSolutionsToProblemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapSolutionsToProblems(requestParameters: BackofficeApiMapSolutionsToProblemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.mapSolutionsToProblems(requestParameters.id, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh bank account data
         * @param {BackofficeApiRefreshBankAccountDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBankAccountData(requestParameters: BackofficeApiRefreshBankAccountDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<BankAccount> {
            return localVarFp.refreshBankAccountData(requestParameters.id, requestParameters.balances, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.details, requestParameters.metaData, requestParameters.transactions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiSendAssignmentAttemptEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAssignmentAttemptEmail(requestParameters: BackofficeApiSendAssignmentAttemptEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendAssignmentAttemptEmail(requestParameters.id, requestParameters.emails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiSetGocardlessAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGocardlessAccount(requestParameters: BackofficeApiSetGocardlessAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setGocardlessAccount(requestParameters.gocardlessSetAccountsRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiSetGocardlessInstitutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGocardlessInstitution(requestParameters: BackofficeApiSetGocardlessInstitutionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setGocardlessInstitution(requestParameters.gocardlessSetInstitutionRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiStartProblemsImportProcessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProblemsImportProcess(requestParameters: BackofficeApiStartProblemsImportProcessRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startProblemsImportProcess(requestParameters.id, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiSubmitAssignmentAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAssignmentAttempt(requestParameters: BackofficeApiSubmitAssignmentAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentAttempt> {
            return localVarFp.submitAssignmentAttempt(requestParameters.file, requestParameters.assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiUpdateAssignmentAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentAttempt(requestParameters: BackofficeApiUpdateAssignmentAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentAttempt> {
            return localVarFp.updateAssignmentAttempt(requestParameters.id, requestParameters.file, requestParameters.user, requestParameters.reviewStatus, requestParameters.assignment, requestParameters.isReviewSentToUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiUpdateAssignmentReviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentReview(requestParameters: BackofficeApiUpdateAssignmentReviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentReview> {
            return localVarFp.updateAssignmentReview(requestParameters.id, requestParameters.file, requestParameters.reviewStatus, requestParameters.comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiUpdateProblemsImportCandidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProblemsImportCandidate(requestParameters: BackofficeApiUpdateProblemsImportCandidateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportCandidate> {
            return localVarFp.updateProblemsImportCandidate(requestParameters.id, requestParameters.patchedProblemsImportCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BackofficeApiUpdateProblemsImportImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProblemsImportImage(requestParameters: BackofficeApiUpdateProblemsImportImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemsImportImage> {
            return localVarFp.updateProblemsImportImage(requestParameters.id, requestParameters.patchedProblemsImportImageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for backofficeApiAccountingCreate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAccountingCreateRequest
 */
export interface BackofficeApiBackofficeApiAccountingCreateRequest {
    /**
     * 
     * @type {BankAccountRequest}
     * @memberof BackofficeApiBackofficeApiAccountingCreate
     */
    readonly bankAccountRequest: BankAccountRequest
}

/**
 * Request parameters for backofficeApiAccountingDestroy operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAccountingDestroyRequest
 */
export interface BackofficeApiBackofficeApiAccountingDestroyRequest {
    /**
     * A unique integer value identifying this bank account.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAccountingDestroy
     */
    readonly id: number
}

/**
 * Request parameters for backofficeApiAccountingList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAccountingListRequest
 */
export interface BackofficeApiBackofficeApiAccountingListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAccountingList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAccountingList
     */
    readonly offset?: number
}

/**
 * Request parameters for backofficeApiAccountingPartialUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAccountingPartialUpdateRequest
 */
export interface BackofficeApiBackofficeApiAccountingPartialUpdateRequest {
    /**
     * A unique integer value identifying this bank account.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAccountingPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedBankAccountRequest}
     * @memberof BackofficeApiBackofficeApiAccountingPartialUpdate
     */
    readonly patchedBankAccountRequest?: PatchedBankAccountRequest
}

/**
 * Request parameters for backofficeApiAccountingUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAccountingUpdateRequest
 */
export interface BackofficeApiBackofficeApiAccountingUpdateRequest {
    /**
     * A unique integer value identifying this bank account.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAccountingUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BankAccountRequest}
     * @memberof BackofficeApiBackofficeApiAccountingUpdate
     */
    readonly bankAccountRequest: BankAccountRequest
}

/**
 * Request parameters for backofficeApiAssignmentAttemptPartialUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAssignmentAttemptPartialUpdateRequest
 */
export interface BackofficeApiBackofficeApiAssignmentAttemptPartialUpdateRequest {
    /**
     * A unique integer value identifying this assignment attempt.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAssignmentAttemptPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {CustomDocumentRequest}
     * @memberof BackofficeApiBackofficeApiAssignmentAttemptPartialUpdate
     */
    readonly file?: CustomDocumentRequest

    /**
     * 
     * @type {UserRequest}
     * @memberof BackofficeApiBackofficeApiAssignmentAttemptPartialUpdate
     */
    readonly user?: UserRequest

    /**
     * 
     * @type {AssignmentAttemptReviewStatusEnum}
     * @memberof BackofficeApiBackofficeApiAssignmentAttemptPartialUpdate
     */
    readonly reviewStatus?: AssignmentAttemptReviewStatusEnum

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiBackofficeApiAssignmentAttemptPartialUpdate
     */
    readonly isReviewSentToUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAssignmentAttemptPartialUpdate
     */
    readonly assignment?: number
}

/**
 * Request parameters for backofficeApiAssignmentReviewDestroy operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAssignmentReviewDestroyRequest
 */
export interface BackofficeApiBackofficeApiAssignmentReviewDestroyRequest {
    /**
     * A unique integer value identifying this assignment review.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewDestroy
     */
    readonly id: number
}

/**
 * Request parameters for backofficeApiAssignmentReviewUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiAssignmentReviewUpdateRequest
 */
export interface BackofficeApiBackofficeApiAssignmentReviewUpdateRequest {
    /**
     * A unique integer value identifying this assignment review.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AssignmentReviewerRequest}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewUpdate
     */
    readonly reviewer: AssignmentReviewerRequest

    /**
     * 
     * @type {CustomDocumentRequest}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewUpdate
     */
    readonly file: CustomDocumentRequest

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewUpdate
     */
    readonly assignmentAttempt: number

    /**
     * 
     * @type {ReviewStatusCdfEnum}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewUpdate
     */
    readonly reviewStatus?: ReviewStatusCdfEnum

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiBackofficeApiAssignmentReviewUpdate
     */
    readonly comment?: string | null
}

/**
 * Request parameters for backofficeApiProblemSourceListingCreate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemSourceListingCreateRequest
 */
export interface BackofficeApiBackofficeApiProblemSourceListingCreateRequest {
    /**
     * 
     * @type {ProblemSourceListingRequest}
     * @memberof BackofficeApiBackofficeApiProblemSourceListingCreate
     */
    readonly problemSourceListingRequest: ProblemSourceListingRequest
}

/**
 * Request parameters for backofficeApiProblemSourceListingDestroy operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemSourceListingDestroyRequest
 */
export interface BackofficeApiBackofficeApiProblemSourceListingDestroyRequest {
    /**
     * A unique integer value identifying this problem source listing.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemSourceListingDestroy
     */
    readonly id: number
}

/**
 * Request parameters for backofficeApiProblemSourceListingPartialUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemSourceListingPartialUpdateRequest
 */
export interface BackofficeApiBackofficeApiProblemSourceListingPartialUpdateRequest {
    /**
     * A unique integer value identifying this problem source listing.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemSourceListingPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProblemSourceListingRequest}
     * @memberof BackofficeApiBackofficeApiProblemSourceListingPartialUpdate
     */
    readonly patchedProblemSourceListingRequest?: PatchedProblemSourceListingRequest
}

/**
 * Request parameters for backofficeApiProblemSourceListingUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemSourceListingUpdateRequest
 */
export interface BackofficeApiBackofficeApiProblemSourceListingUpdateRequest {
    /**
     * A unique integer value identifying this problem source listing.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemSourceListingUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProblemSourceListingRequest}
     * @memberof BackofficeApiBackofficeApiProblemSourceListingUpdate
     */
    readonly problemSourceListingRequest: ProblemSourceListingRequest
}

/**
 * Request parameters for backofficeApiProblemsImportAttemptDestroy operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportAttemptDestroyRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportAttemptDestroyRequest {
    /**
     * A unique integer value identifying this problems import attempt.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptDestroy
     */
    readonly id: number
}

/**
 * Request parameters for backofficeApiProblemsImportAttemptList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportAttemptListRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportAttemptListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptList
     */
    readonly offset?: number
}

/**
 * Request parameters for backofficeApiProblemsImportAttemptPartialUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdateRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdateRequest {
    /**
     * A unique integer value identifying this problems import attempt.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProblemsImportAttemptRequest}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdate
     */
    readonly patchedProblemsImportAttemptRequest?: PatchedProblemsImportAttemptRequest
}

/**
 * Request parameters for backofficeApiProblemsImportAttemptRetrieve operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportAttemptRetrieveRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportAttemptRetrieveRequest {
    /**
     * A unique integer value identifying this problems import attempt.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for backofficeApiProblemsImportAttemptUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportAttemptUpdateRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportAttemptUpdateRequest {
    /**
     * A unique integer value identifying this problems import attempt.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProblemsImportAttemptRequest}
     * @memberof BackofficeApiBackofficeApiProblemsImportAttemptUpdate
     */
    readonly problemsImportAttemptRequest: ProblemsImportAttemptRequest
}

/**
 * Request parameters for backofficeApiProblemsImportCandidateUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportCandidateUpdateRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportCandidateUpdateRequest {
    /**
     * A unique integer value identifying this Problem import candidate.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportCandidateUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProblemsImportCandidateRequest}
     * @memberof BackofficeApiBackofficeApiProblemsImportCandidateUpdate
     */
    readonly problemsImportCandidateRequest: ProblemsImportCandidateRequest
}

/**
 * Request parameters for backofficeApiProblemsImportImageCreate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportImageCreateRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportImageCreateRequest {
    /**
     * 
     * @type {ProblemsImportImageRequest}
     * @memberof BackofficeApiBackofficeApiProblemsImportImageCreate
     */
    readonly problemsImportImageRequest: ProblemsImportImageRequest
}

/**
 * Request parameters for backofficeApiProblemsImportImageUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiProblemsImportImageUpdateRequest
 */
export interface BackofficeApiBackofficeApiProblemsImportImageUpdateRequest {
    /**
     * A unique integer value identifying this Problem import image.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiProblemsImportImageUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProblemsImportImageRequest}
     * @memberof BackofficeApiBackofficeApiProblemsImportImageUpdate
     */
    readonly problemsImportImageRequest: ProblemsImportImageRequest
}

/**
 * Request parameters for backofficeApiVideoDestroy operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiVideoDestroyRequest
 */
export interface BackofficeApiBackofficeApiVideoDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof BackofficeApiBackofficeApiVideoDestroy
     */
    readonly id: string
}

/**
 * Request parameters for backofficeApiVideoList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiVideoListRequest
 */
export interface BackofficeApiBackofficeApiVideoListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiVideoList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiBackofficeApiVideoList
     */
    readonly offset?: number
}

/**
 * Request parameters for backofficeApiVideoPartialUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiVideoPartialUpdateRequest
 */
export interface BackofficeApiBackofficeApiVideoPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BackofficeApiBackofficeApiVideoPartialUpdate
     */
    readonly id: string

    /**
     * 
     * @type {PatchedVideoRequest}
     * @memberof BackofficeApiBackofficeApiVideoPartialUpdate
     */
    readonly patchedVideoRequest?: PatchedVideoRequest
}

/**
 * Request parameters for backofficeApiVideoRetrieve operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiVideoRetrieveRequest
 */
export interface BackofficeApiBackofficeApiVideoRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof BackofficeApiBackofficeApiVideoRetrieve
     */
    readonly id: string
}

/**
 * Request parameters for backofficeApiVideoUpdate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiBackofficeApiVideoUpdateRequest
 */
export interface BackofficeApiBackofficeApiVideoUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BackofficeApiBackofficeApiVideoUpdate
     */
    readonly id: string

    /**
     * 
     * @type {VideoRequest}
     * @memberof BackofficeApiBackofficeApiVideoUpdate
     */
    readonly videoRequest: VideoRequest
}

/**
 * Request parameters for createAssignmentAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiCreateAssignmentAttemptRequest
 */
export interface BackofficeApiCreateAssignmentAttemptRequest {
    /**
     * 
     * @type {CustomDocumentRequest}
     * @memberof BackofficeApiCreateAssignmentAttempt
     */
    readonly file: CustomDocumentRequest

    /**
     * 
     * @type {UserRequest}
     * @memberof BackofficeApiCreateAssignmentAttempt
     */
    readonly user: UserRequest

    /**
     * 
     * @type {AssignmentAttemptReviewStatusEnum}
     * @memberof BackofficeApiCreateAssignmentAttempt
     */
    readonly reviewStatus: AssignmentAttemptReviewStatusEnum

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiCreateAssignmentAttempt
     */
    readonly assignment: number

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiCreateAssignmentAttempt
     */
    readonly isReviewSentToUser?: boolean
}

/**
 * Request parameters for createAssignmentReview operation in BackofficeApi.
 * @export
 * @interface BackofficeApiCreateAssignmentReviewRequest
 */
export interface BackofficeApiCreateAssignmentReviewRequest {
    /**
     * 
     * @type {number}
     * @memberof BackofficeApiCreateAssignmentReview
     */
    readonly reviewer: number

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiCreateAssignmentReview
     */
    readonly assignmentAttempt: number

    /**
     * 
     * @type {ReviewStatusCdfEnum}
     * @memberof BackofficeApiCreateAssignmentReview
     */
    readonly reviewStatus?: ReviewStatusCdfEnum

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiCreateAssignmentReview
     */
    readonly comment?: string | null

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiCreateAssignmentReview
     */
    readonly file?: number | null
}

/**
 * Request parameters for createProblemsImportAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiCreateProblemsImportAttemptRequest
 */
export interface BackofficeApiCreateProblemsImportAttemptRequest {
    /**
     * 
     * @type {ProblemsImportAttemptCreateRequestRequest}
     * @memberof BackofficeApiCreateProblemsImportAttempt
     */
    readonly problemsImportAttemptCreateRequestRequest: ProblemsImportAttemptCreateRequestRequest
}

/**
 * Request parameters for createProblemsImportCandidate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiCreateProblemsImportCandidateRequest
 */
export interface BackofficeApiCreateProblemsImportCandidateRequest {
    /**
     * 
     * @type {ProblemsImportCandidateRequest}
     * @memberof BackofficeApiCreateProblemsImportCandidate
     */
    readonly problemsImportCandidateRequest: ProblemsImportCandidateRequest
}

/**
 * Request parameters for createVideo operation in BackofficeApi.
 * @export
 * @interface BackofficeApiCreateVideoRequest
 */
export interface BackofficeApiCreateVideoRequest {
    /**
     * 
     * @type {VideoCreateRequest}
     * @memberof BackofficeApiCreateVideo
     */
    readonly videoCreateRequest: VideoCreateRequest
}

/**
 * Request parameters for deleteAssignmentAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiDeleteAssignmentAttemptRequest
 */
export interface BackofficeApiDeleteAssignmentAttemptRequest {
    /**
     * A unique integer value identifying this assignment attempt.
     * @type {number}
     * @memberof BackofficeApiDeleteAssignmentAttempt
     */
    readonly id: number
}

/**
 * Request parameters for deleteProblemsImportCandidate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiDeleteProblemsImportCandidateRequest
 */
export interface BackofficeApiDeleteProblemsImportCandidateRequest {
    /**
     * A unique integer value identifying this Problem import candidate.
     * @type {number}
     * @memberof BackofficeApiDeleteProblemsImportCandidate
     */
    readonly id: number
}

/**
 * Request parameters for deleteProblemsImportImage operation in BackofficeApi.
 * @export
 * @interface BackofficeApiDeleteProblemsImportImageRequest
 */
export interface BackofficeApiDeleteProblemsImportImageRequest {
    /**
     * A unique integer value identifying this Problem import image.
     * @type {number}
     * @memberof BackofficeApiDeleteProblemsImportImage
     */
    readonly id: number
}

/**
 * Request parameters for generateBoards operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGenerateBoardsRequest
 */
export interface BackofficeApiGenerateBoardsRequest {
    /**
     * 
     * @type {ProblemBoardsRequestRequest}
     * @memberof BackofficeApiGenerateBoards
     */
    readonly problemBoardsRequestRequest: ProblemBoardsRequestRequest
}

/**
 * Request parameters for generateProblemCandidates operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGenerateProblemCandidatesRequest
 */
export interface BackofficeApiGenerateProblemCandidatesRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof BackofficeApiGenerateProblemCandidates
     */
    readonly id: number
}

/**
 * Request parameters for generateProblemSources operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGenerateProblemSourcesRequest
 */
export interface BackofficeApiGenerateProblemSourcesRequest {
    /**
     * 
     * @type {GenerateProblemSourcesRequestRequest}
     * @memberof BackofficeApiGenerateProblemSources
     */
    readonly generateProblemSourcesRequestRequest: GenerateProblemSourcesRequestRequest
}

/**
 * Request parameters for getAllPageCacheKeyBases operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAllPageCacheKeyBasesRequest
 */
export interface BackofficeApiGetAllPageCacheKeyBasesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetAllPageCacheKeyBases
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetAllPageCacheKeyBases
     */
    readonly offset?: number
}

/**
 * Request parameters for getAssignment operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAssignmentRequest
 */
export interface BackofficeApiGetAssignmentRequest {
    /**
     * A unique integer value identifying this assignment.
     * @type {number}
     * @memberof BackofficeApiGetAssignment
     */
    readonly id: number
}

/**
 * Request parameters for getAssignmentAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAssignmentAttemptRequest
 */
export interface BackofficeApiGetAssignmentAttemptRequest {
    /**
     * A unique integer value identifying this assignment attempt.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentAttempt
     */
    readonly id: number
}

/**
 * Request parameters for getAssignmentAttemptList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAssignmentAttemptListRequest
 */
export interface BackofficeApiGetAssignmentAttemptListRequest {
    /**
     * 
     * @type {number}
     * @memberof BackofficeApiGetAssignmentAttemptList
     */
    readonly assignment?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentAttemptList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentAttemptList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiGetAssignmentAttemptList
     */
    readonly user?: number
}

/**
 * Request parameters for getAssignmentList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAssignmentListRequest
 */
export interface BackofficeApiGetAssignmentListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentList
     */
    readonly offset?: number
}

/**
 * Request parameters for getAssignmentReview operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAssignmentReviewRequest
 */
export interface BackofficeApiGetAssignmentReviewRequest {
    /**
     * A unique integer value identifying this assignment review.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentReview
     */
    readonly id: number
}

/**
 * Request parameters for getAssignmentReviewList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetAssignmentReviewListRequest
 */
export interface BackofficeApiGetAssignmentReviewListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentReviewList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetAssignmentReviewList
     */
    readonly offset?: number
}

/**
 * Request parameters for getBankAccountData operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetBankAccountDataRequest
 */
export interface BackofficeApiGetBankAccountDataRequest {
    /**
     * A unique integer value identifying this bank account.
     * @type {number}
     * @memberof BackofficeApiGetBankAccountData
     */
    readonly id: number
}

/**
 * Request parameters for getGoogleDriveFolders operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetGoogleDriveFoldersRequest
 */
export interface BackofficeApiGetGoogleDriveFoldersRequest {
    /**
     * 
     * @type {string}
     * @memberof BackofficeApiGetGoogleDriveFolders
     */
    readonly parentId?: string
}

/**
 * Request parameters for getLatestUserAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetLatestUserAttemptRequest
 */
export interface BackofficeApiGetLatestUserAttemptRequest {
    /**
     * 
     * @type {number}
     * @memberof BackofficeApiGetLatestUserAttempt
     */
    readonly assignmentId: number
}

/**
 * Request parameters for getNotionEntries operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetNotionEntriesRequest
 */
export interface BackofficeApiGetNotionEntriesRequest {
    /**
     * A unique integer value identifying this bank account.
     * @type {number}
     * @memberof BackofficeApiGetNotionEntries
     */
    readonly id: number
}

/**
 * Request parameters for getProblemSourceGenrator operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemSourceGenratorRequest
 */
export interface BackofficeApiGetProblemSourceGenratorRequest {
    /**
     * A unique integer value identifying this problem source generator.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceGenrator
     */
    readonly id: number
}

/**
 * Request parameters for getProblemSourceGenratorList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemSourceGenratorListRequest
 */
export interface BackofficeApiGetProblemSourceGenratorListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceGenratorList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceGenratorList
     */
    readonly offset?: number
}

/**
 * Request parameters for getProblemSourceListing operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemSourceListingRequest
 */
export interface BackofficeApiGetProblemSourceListingRequest {
    /**
     * A unique integer value identifying this problem source listing.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceListing
     */
    readonly id: number
}

/**
 * Request parameters for getProblemSourceListingList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemSourceListingListRequest
 */
export interface BackofficeApiGetProblemSourceListingListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceListingList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceListingList
     */
    readonly offset?: number
}

/**
 * Request parameters for getProblemSourceSectionList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemSourceSectionListRequest
 */
export interface BackofficeApiGetProblemSourceSectionListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceSectionList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceSectionList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiGetProblemSourceSectionList
     */
    readonly problemSource?: number
}

/**
 * Request parameters for getProblemsImportCandidate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemsImportCandidateRequest
 */
export interface BackofficeApiGetProblemsImportCandidateRequest {
    /**
     * A unique integer value identifying this Problem import candidate.
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportCandidate
     */
    readonly id: number
}

/**
 * Request parameters for getProblemsImportCandidateList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemsImportCandidateListRequest
 */
export interface BackofficeApiGetProblemsImportCandidateListRequest {
    /**
     * 
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportCandidateList
     */
    readonly problemSource: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportCandidateList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportCandidateList
     */
    readonly offset?: number

    /**
     * 
     * @type {'imported' | 'rejected' | 'unprocessed'}
     * @memberof BackofficeApiGetProblemsImportCandidateList
     */
    readonly status?: GetProblemsImportCandidateListStatusEnum

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiGetProblemsImportCandidateList
     */
    readonly statusIn?: string
}

/**
 * Request parameters for getProblemsImportImage operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemsImportImageRequest
 */
export interface BackofficeApiGetProblemsImportImageRequest {
    /**
     * A unique integer value identifying this Problem import image.
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportImage
     */
    readonly id: number
}

/**
 * Request parameters for getProblemsImportImageList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetProblemsImportImageListRequest
 */
export interface BackofficeApiGetProblemsImportImageListRequest {
    /**
     * 
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportImageList
     */
    readonly problemSource: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportImageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetProblemsImportImageList
     */
    readonly offset?: number

    /**
     * 
     * @type {'processed' | 'ready' | 'rejected' | 'unprocessed'}
     * @memberof BackofficeApiGetProblemsImportImageList
     */
    readonly status?: GetProblemsImportImageListStatusEnum

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiGetProblemsImportImageList
     */
    readonly statusIn?: string
}

/**
 * Request parameters for getSubject operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetSubjectRequest
 */
export interface BackofficeApiGetSubjectRequest {
    /**
     * A unique integer value identifying this subject.
     * @type {number}
     * @memberof BackofficeApiGetSubject
     */
    readonly id: number
}

/**
 * Request parameters for getSubjectList operation in BackofficeApi.
 * @export
 * @interface BackofficeApiGetSubjectListRequest
 */
export interface BackofficeApiGetSubjectListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BackofficeApiGetSubjectList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof BackofficeApiGetSubjectList
     */
    readonly offset?: number
}

/**
 * Request parameters for initiatePdfPrint operation in BackofficeApi.
 * @export
 * @interface BackofficeApiInitiatePdfPrintRequest
 */
export interface BackofficeApiInitiatePdfPrintRequest {
    /**
     * 
     * @type {InitiatePdfPrintRequestRequest}
     * @memberof BackofficeApiInitiatePdfPrint
     */
    readonly initiatePdfPrintRequestRequest: InitiatePdfPrintRequestRequest
}

/**
 * Request parameters for mapSolutionsToProblems operation in BackofficeApi.
 * @export
 * @interface BackofficeApiMapSolutionsToProblemsRequest
 */
export interface BackofficeApiMapSolutionsToProblemsRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof BackofficeApiMapSolutionsToProblems
     */
    readonly id: number

    /**
     * 
     * @type {File}
     * @memberof BackofficeApiMapSolutionsToProblems
     */
    readonly file: File
}

/**
 * Request parameters for refreshBankAccountData operation in BackofficeApi.
 * @export
 * @interface BackofficeApiRefreshBankAccountDataRequest
 */
export interface BackofficeApiRefreshBankAccountDataRequest {
    /**
     * A unique integer value identifying this bank account.
     * @type {number}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly id: number

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly balances?: boolean

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly dateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly dateTo?: string

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly details?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly metaData?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiRefreshBankAccountData
     */
    readonly transactions?: boolean
}

/**
 * Request parameters for sendAssignmentAttemptEmail operation in BackofficeApi.
 * @export
 * @interface BackofficeApiSendAssignmentAttemptEmailRequest
 */
export interface BackofficeApiSendAssignmentAttemptEmailRequest {
    /**
     * A unique integer value identifying this assignment attempt.
     * @type {number}
     * @memberof BackofficeApiSendAssignmentAttemptEmail
     */
    readonly id: number

    /**
     * 
     * @type {Array<string>}
     * @memberof BackofficeApiSendAssignmentAttemptEmail
     */
    readonly emails?: Array<string> | null
}

/**
 * Request parameters for setGocardlessAccount operation in BackofficeApi.
 * @export
 * @interface BackofficeApiSetGocardlessAccountRequest
 */
export interface BackofficeApiSetGocardlessAccountRequest {
    /**
     * 
     * @type {GocardlessSetAccountsRequestRequest}
     * @memberof BackofficeApiSetGocardlessAccount
     */
    readonly gocardlessSetAccountsRequestRequest: GocardlessSetAccountsRequestRequest
}

/**
 * Request parameters for setGocardlessInstitution operation in BackofficeApi.
 * @export
 * @interface BackofficeApiSetGocardlessInstitutionRequest
 */
export interface BackofficeApiSetGocardlessInstitutionRequest {
    /**
     * 
     * @type {GocardlessSetInstitutionRequestRequest}
     * @memberof BackofficeApiSetGocardlessInstitution
     */
    readonly gocardlessSetInstitutionRequestRequest: GocardlessSetInstitutionRequestRequest
}

/**
 * Request parameters for startProblemsImportProcess operation in BackofficeApi.
 * @export
 * @interface BackofficeApiStartProblemsImportProcessRequest
 */
export interface BackofficeApiStartProblemsImportProcessRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof BackofficeApiStartProblemsImportProcess
     */
    readonly id: number

    /**
     * 
     * @type {File}
     * @memberof BackofficeApiStartProblemsImportProcess
     */
    readonly file: File
}

/**
 * Request parameters for submitAssignmentAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiSubmitAssignmentAttemptRequest
 */
export interface BackofficeApiSubmitAssignmentAttemptRequest {
    /**
     * 
     * @type {File}
     * @memberof BackofficeApiSubmitAssignmentAttempt
     */
    readonly file: File

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiSubmitAssignmentAttempt
     */
    readonly assignmentId: number
}

/**
 * Request parameters for updateAssignmentAttempt operation in BackofficeApi.
 * @export
 * @interface BackofficeApiUpdateAssignmentAttemptRequest
 */
export interface BackofficeApiUpdateAssignmentAttemptRequest {
    /**
     * A unique integer value identifying this assignment attempt.
     * @type {number}
     * @memberof BackofficeApiUpdateAssignmentAttempt
     */
    readonly id: number

    /**
     * 
     * @type {CustomDocumentRequest}
     * @memberof BackofficeApiUpdateAssignmentAttempt
     */
    readonly file: CustomDocumentRequest

    /**
     * 
     * @type {UserRequest}
     * @memberof BackofficeApiUpdateAssignmentAttempt
     */
    readonly user: UserRequest

    /**
     * 
     * @type {AssignmentAttemptReviewStatusEnum}
     * @memberof BackofficeApiUpdateAssignmentAttempt
     */
    readonly reviewStatus: AssignmentAttemptReviewStatusEnum

    /**
     * 
     * @type {number}
     * @memberof BackofficeApiUpdateAssignmentAttempt
     */
    readonly assignment: number

    /**
     * 
     * @type {boolean}
     * @memberof BackofficeApiUpdateAssignmentAttempt
     */
    readonly isReviewSentToUser?: boolean
}

/**
 * Request parameters for updateAssignmentReview operation in BackofficeApi.
 * @export
 * @interface BackofficeApiUpdateAssignmentReviewRequest
 */
export interface BackofficeApiUpdateAssignmentReviewRequest {
    /**
     * A unique integer value identifying this assignment review.
     * @type {number}
     * @memberof BackofficeApiUpdateAssignmentReview
     */
    readonly id: number

    /**
     * 
     * @type {File}
     * @memberof BackofficeApiUpdateAssignmentReview
     */
    readonly file?: File

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiUpdateAssignmentReview
     */
    readonly reviewStatus?: string

    /**
     * 
     * @type {string}
     * @memberof BackofficeApiUpdateAssignmentReview
     */
    readonly comment?: string
}

/**
 * Request parameters for updateProblemsImportCandidate operation in BackofficeApi.
 * @export
 * @interface BackofficeApiUpdateProblemsImportCandidateRequest
 */
export interface BackofficeApiUpdateProblemsImportCandidateRequest {
    /**
     * A unique integer value identifying this Problem import candidate.
     * @type {number}
     * @memberof BackofficeApiUpdateProblemsImportCandidate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProblemsImportCandidateRequest}
     * @memberof BackofficeApiUpdateProblemsImportCandidate
     */
    readonly patchedProblemsImportCandidateRequest?: PatchedProblemsImportCandidateRequest
}

/**
 * Request parameters for updateProblemsImportImage operation in BackofficeApi.
 * @export
 * @interface BackofficeApiUpdateProblemsImportImageRequest
 */
export interface BackofficeApiUpdateProblemsImportImageRequest {
    /**
     * A unique integer value identifying this Problem import image.
     * @type {number}
     * @memberof BackofficeApiUpdateProblemsImportImage
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProblemsImportImageRequest}
     * @memberof BackofficeApiUpdateProblemsImportImage
     */
    readonly patchedProblemsImportImageRequest?: PatchedProblemsImportImageRequest
}

/**
 * BackofficeApi - object-oriented interface
 * @export
 * @class BackofficeApi
 * @extends {BaseAPI}
 */
export class BackofficeApi extends BaseAPI {
    /**
     * 
     * @param {BackofficeApiBackofficeApiAccountingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAccountingCreate(requestParameters: BackofficeApiBackofficeApiAccountingCreateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAccountingCreate(requestParameters.bankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAccountingDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAccountingDestroy(requestParameters: BackofficeApiBackofficeApiAccountingDestroyRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAccountingDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAccountingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAccountingList(requestParameters: BackofficeApiBackofficeApiAccountingListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAccountingList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAccountingPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAccountingPartialUpdate(requestParameters: BackofficeApiBackofficeApiAccountingPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAccountingPartialUpdate(requestParameters.id, requestParameters.patchedBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAccountingUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAccountingUpdate(requestParameters: BackofficeApiBackofficeApiAccountingUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAccountingUpdate(requestParameters.id, requestParameters.bankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAssignmentAttemptPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAssignmentAttemptPartialUpdate(requestParameters: BackofficeApiBackofficeApiAssignmentAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAssignmentAttemptPartialUpdate(requestParameters.id, requestParameters.file, requestParameters.user, requestParameters.reviewStatus, requestParameters.isReviewSentToUser, requestParameters.assignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAssignmentReviewDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAssignmentReviewDestroy(requestParameters: BackofficeApiBackofficeApiAssignmentReviewDestroyRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAssignmentReviewDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiAssignmentReviewUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiAssignmentReviewUpdate(requestParameters: BackofficeApiBackofficeApiAssignmentReviewUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiAssignmentReviewUpdate(requestParameters.id, requestParameters.reviewer, requestParameters.file, requestParameters.assignmentAttempt, requestParameters.reviewStatus, requestParameters.comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemSourceListingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemSourceListingCreate(requestParameters: BackofficeApiBackofficeApiProblemSourceListingCreateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemSourceListingCreate(requestParameters.problemSourceListingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemSourceListingDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemSourceListingDestroy(requestParameters: BackofficeApiBackofficeApiProblemSourceListingDestroyRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemSourceListingDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemSourceListingPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemSourceListingPartialUpdate(requestParameters: BackofficeApiBackofficeApiProblemSourceListingPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemSourceListingPartialUpdate(requestParameters.id, requestParameters.patchedProblemSourceListingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemSourceListingUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemSourceListingUpdate(requestParameters: BackofficeApiBackofficeApiProblemSourceListingUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemSourceListingUpdate(requestParameters.id, requestParameters.problemSourceListingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportAttemptDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportAttemptDestroy(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptDestroyRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportAttemptDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportAttemptListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportAttemptList(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportAttemptList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportAttemptPartialUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportAttemptPartialUpdate(requestParameters.id, requestParameters.patchedProblemsImportAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportAttemptRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportAttemptRetrieve(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptRetrieveRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportAttemptRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportAttemptUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportAttemptUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportAttemptUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportAttemptUpdate(requestParameters.id, requestParameters.problemsImportAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportCandidateUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportCandidateUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportCandidateUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportCandidateUpdate(requestParameters.id, requestParameters.problemsImportCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportImageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportImageCreate(requestParameters: BackofficeApiBackofficeApiProblemsImportImageCreateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportImageCreate(requestParameters.problemsImportImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiProblemsImportImageUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiProblemsImportImageUpdate(requestParameters: BackofficeApiBackofficeApiProblemsImportImageUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiProblemsImportImageUpdate(requestParameters.id, requestParameters.problemsImportImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiVideoDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiVideoDestroy(requestParameters: BackofficeApiBackofficeApiVideoDestroyRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiVideoDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiVideoListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiVideoList(requestParameters: BackofficeApiBackofficeApiVideoListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiVideoList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiVideoPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiVideoPartialUpdate(requestParameters: BackofficeApiBackofficeApiVideoPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiVideoPartialUpdate(requestParameters.id, requestParameters.patchedVideoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiVideoRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiVideoRetrieve(requestParameters: BackofficeApiBackofficeApiVideoRetrieveRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiVideoRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiBackofficeApiVideoUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public backofficeApiVideoUpdate(requestParameters: BackofficeApiBackofficeApiVideoUpdateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).backofficeApiVideoUpdate(requestParameters.id, requestParameters.videoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiCreateAssignmentAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public createAssignmentAttempt(requestParameters: BackofficeApiCreateAssignmentAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).createAssignmentAttempt(requestParameters.file, requestParameters.user, requestParameters.reviewStatus, requestParameters.assignment, requestParameters.isReviewSentToUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiCreateAssignmentReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public createAssignmentReview(requestParameters: BackofficeApiCreateAssignmentReviewRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).createAssignmentReview(requestParameters.reviewer, requestParameters.assignmentAttempt, requestParameters.reviewStatus, requestParameters.comment, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiCreateProblemsImportAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public createProblemsImportAttempt(requestParameters: BackofficeApiCreateProblemsImportAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).createProblemsImportAttempt(requestParameters.problemsImportAttemptCreateRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiCreateProblemsImportCandidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public createProblemsImportCandidate(requestParameters: BackofficeApiCreateProblemsImportCandidateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).createProblemsImportCandidate(requestParameters.problemsImportCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiCreateVideoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public createVideo(requestParameters: BackofficeApiCreateVideoRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).createVideo(requestParameters.videoCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiDeleteAssignmentAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public deleteAssignmentAttempt(requestParameters: BackofficeApiDeleteAssignmentAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).deleteAssignmentAttempt(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiDeleteProblemsImportCandidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public deleteProblemsImportCandidate(requestParameters: BackofficeApiDeleteProblemsImportCandidateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).deleteProblemsImportCandidate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiDeleteProblemsImportImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public deleteProblemsImportImage(requestParameters: BackofficeApiDeleteProblemsImportImageRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).deleteProblemsImportImage(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGenerateBoardsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public generateBoards(requestParameters: BackofficeApiGenerateBoardsRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).generateBoards(requestParameters.problemBoardsRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGenerateProblemCandidatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public generateProblemCandidates(requestParameters: BackofficeApiGenerateProblemCandidatesRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).generateProblemCandidates(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGenerateProblemSourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public generateProblemSources(requestParameters: BackofficeApiGenerateProblemSourcesRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).generateProblemSources(requestParameters.generateProblemSourcesRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAllPageCacheKeyBasesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAllPageCacheKeyBases(requestParameters: BackofficeApiGetAllPageCacheKeyBasesRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAllPageCacheKeyBases(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAssignment(requestParameters: BackofficeApiGetAssignmentRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAssignment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAssignmentAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAssignmentAttempt(requestParameters: BackofficeApiGetAssignmentAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAssignmentAttempt(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAssignmentAttemptListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAssignmentAttemptList(requestParameters: BackofficeApiGetAssignmentAttemptListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAssignmentAttemptList(requestParameters.assignment, requestParameters.limit, requestParameters.offset, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAssignmentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAssignmentList(requestParameters: BackofficeApiGetAssignmentListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAssignmentList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAssignmentReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAssignmentReview(requestParameters: BackofficeApiGetAssignmentReviewRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAssignmentReview(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetAssignmentReviewListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getAssignmentReviewList(requestParameters: BackofficeApiGetAssignmentReviewListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getAssignmentReviewList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank account data
     * @param {BackofficeApiGetBankAccountDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getBankAccountData(requestParameters: BackofficeApiGetBankAccountDataRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getBankAccountData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getGocardlessAccounts(options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getGocardlessAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getGocardlessAuthLink(options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getGocardlessAuthLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getGocardlessInstitutions(options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getGocardlessInstitutions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetGoogleDriveFoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getGoogleDriveFolders(requestParameters: BackofficeApiGetGoogleDriveFoldersRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getGoogleDriveFolders(requestParameters.parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetLatestUserAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getLatestUserAttempt(requestParameters: BackofficeApiGetLatestUserAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getLatestUserAttempt(requestParameters.assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getNavigationItems(options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getNavigationItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notion entries
     * @param {BackofficeApiGetNotionEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getNotionEntries(requestParameters: BackofficeApiGetNotionEntriesRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getNotionEntries(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemSourceGenratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemSourceGenrator(requestParameters: BackofficeApiGetProblemSourceGenratorRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemSourceGenrator(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemSourceGenratorListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemSourceGenratorList(requestParameters: BackofficeApiGetProblemSourceGenratorListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemSourceGenratorList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemSourceListingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemSourceListing(requestParameters: BackofficeApiGetProblemSourceListingRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemSourceListing(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemSourceListingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemSourceListingList(requestParameters: BackofficeApiGetProblemSourceListingListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemSourceListingList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemSourceSectionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemSourceSectionList(requestParameters: BackofficeApiGetProblemSourceSectionListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemSourceSectionList(requestParameters.limit, requestParameters.offset, requestParameters.problemSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemsImportCandidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemsImportCandidate(requestParameters: BackofficeApiGetProblemsImportCandidateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemsImportCandidate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemsImportCandidateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemsImportCandidateList(requestParameters: BackofficeApiGetProblemsImportCandidateListRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemsImportCandidateList(requestParameters.problemSource, requestParameters.limit, requestParameters.offset, requestParameters.status, requestParameters.statusIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemsImportImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemsImportImage(requestParameters: BackofficeApiGetProblemsImportImageRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemsImportImage(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetProblemsImportImageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getProblemsImportImageList(requestParameters: BackofficeApiGetProblemsImportImageListRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getProblemsImportImageList(requestParameters.problemSource, requestParameters.limit, requestParameters.offset, requestParameters.status, requestParameters.statusIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetSubjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getSubject(requestParameters: BackofficeApiGetSubjectRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getSubject(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiGetSubjectListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getSubjectList(requestParameters: BackofficeApiGetSubjectListRequest = {}, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getSubjectList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getTrelloBoards(options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getTrelloBoards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public getTrelloWorkspaces(options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).getTrelloWorkspaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiInitiatePdfPrintRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public initiatePdfPrint(requestParameters: BackofficeApiInitiatePdfPrintRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).initiatePdfPrint(requestParameters.initiatePdfPrintRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiMapSolutionsToProblemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public mapSolutionsToProblems(requestParameters: BackofficeApiMapSolutionsToProblemsRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).mapSolutionsToProblems(requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh bank account data
     * @param {BackofficeApiRefreshBankAccountDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public refreshBankAccountData(requestParameters: BackofficeApiRefreshBankAccountDataRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).refreshBankAccountData(requestParameters.id, requestParameters.balances, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.details, requestParameters.metaData, requestParameters.transactions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiSendAssignmentAttemptEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public sendAssignmentAttemptEmail(requestParameters: BackofficeApiSendAssignmentAttemptEmailRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).sendAssignmentAttemptEmail(requestParameters.id, requestParameters.emails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiSetGocardlessAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public setGocardlessAccount(requestParameters: BackofficeApiSetGocardlessAccountRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).setGocardlessAccount(requestParameters.gocardlessSetAccountsRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiSetGocardlessInstitutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public setGocardlessInstitution(requestParameters: BackofficeApiSetGocardlessInstitutionRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).setGocardlessInstitution(requestParameters.gocardlessSetInstitutionRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiStartProblemsImportProcessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public startProblemsImportProcess(requestParameters: BackofficeApiStartProblemsImportProcessRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).startProblemsImportProcess(requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiSubmitAssignmentAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public submitAssignmentAttempt(requestParameters: BackofficeApiSubmitAssignmentAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).submitAssignmentAttempt(requestParameters.file, requestParameters.assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiUpdateAssignmentAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public updateAssignmentAttempt(requestParameters: BackofficeApiUpdateAssignmentAttemptRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).updateAssignmentAttempt(requestParameters.id, requestParameters.file, requestParameters.user, requestParameters.reviewStatus, requestParameters.assignment, requestParameters.isReviewSentToUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiUpdateAssignmentReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public updateAssignmentReview(requestParameters: BackofficeApiUpdateAssignmentReviewRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).updateAssignmentReview(requestParameters.id, requestParameters.file, requestParameters.reviewStatus, requestParameters.comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiUpdateProblemsImportCandidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public updateProblemsImportCandidate(requestParameters: BackofficeApiUpdateProblemsImportCandidateRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).updateProblemsImportCandidate(requestParameters.id, requestParameters.patchedProblemsImportCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BackofficeApiUpdateProblemsImportImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeApi
     */
    public updateProblemsImportImage(requestParameters: BackofficeApiUpdateProblemsImportImageRequest, options?: RawAxiosRequestConfig) {
        return BackofficeApiFp(this.configuration).updateProblemsImportImage(requestParameters.id, requestParameters.patchedProblemsImportImageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProblemsImportCandidateListStatusEnum = {
    Imported: 'imported',
    Rejected: 'rejected',
    Unprocessed: 'unprocessed'
} as const;
export type GetProblemsImportCandidateListStatusEnum = typeof GetProblemsImportCandidateListStatusEnum[keyof typeof GetProblemsImportCandidateListStatusEnum];
/**
 * @export
 */
export const GetProblemsImportImageListStatusEnum = {
    Processed: 'processed',
    Ready: 'ready',
    Rejected: 'rejected',
    Unprocessed: 'unprocessed'
} as const;
export type GetProblemsImportImageListStatusEnum = typeof GetProblemsImportImageListStatusEnum[keyof typeof GetProblemsImportImageListStatusEnum];
